import * as React from 'react'
import { graphql } from 'gatsby'

import { Box, Container, Grid } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import PageHeader from '../components/layout/PageHeader';
import Content from '../components/Content';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import Seo from '../components/Seo';
import { useTranslation } from '../contexts/TranslationProvider';
import addToSchema from '../services/addToSchema';

const SubscriptionsPageTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const page = data.page || {}

  const isBrowser = () => typeof window !== "undefined"
  const queryString = isBrowser() ? window.location.search : [];
  const urlParams = new URLSearchParams(queryString);
  const transactionSuccess = urlParams.get('transaction-success') === 'true'
  const transactionCancel = urlParams.get('transaction-cancel') === 'true'

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          {transactionSuccess &&
            <Grid item xs={12}>
              <Box sx={{
                height: 140,
                background: '#FFF5F5',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                color: 'secondary.main',
                textAlign: 'center',
                justifyContent: 'center'
              }}>
                {t('paymentSuccess')}
              </Box>
            </Grid>}

          {transactionCancel &&
            <Grid item xs={12}>
              <Box sx={{
                height: 140,
                background: '#FFF5F5',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                color: 'secondary.main',
                textAlign: 'center',
                justifyContent: 'center'
              }}>
                {t('paymentCancel')}
              </Box>
            </Grid>}
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <PageHeader icon={page.data.icon} subtitle={page.data.subtitle} />
        </Grid>
        <Grid item xs={12} sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <RichText sx={{
            textAlign: 'left',
            width: {
              xs: 'auto',
              md: '60%'
            }
          }}
            content={page.data.description.richText} />
        </Grid>
      </Grid>
    </Container>
    <Content doc={page} />
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query subscriptionsPageQuery($lang: String) {
    page: prismicSubscriptionsPage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
        }
        subtitle {
          richText
        }
        icon {
          alt
          gatsbyImageData(width: 100)
        }

        description {
          richText
        }    
                
        body {


          
          ... on PrismicSubscriptionsPageDataBodySubscriptions {
            id
            slice_type
            primary {
              subscription_title {
                richText
              }
            }
            items {
              subscription {
                document {
                  ... on PrismicSubscription {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      description {
                        richText
                      }
                      stripe_product_id
                      image {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      labels {
                        name
                        color
                      }
                    }
                  }
                }
              }
            }
          }


        }
      }
    }
  }
`

export default SubscriptionsPageTemplate